<template>
	<layout-default>
		<div>
			<div>
				<b-button
					class="is-danger margin-top-30"
					style="width: 350px"
					:disabled="isResetBtnDisabled"
					@click="onReset"
				>
					Reset credit debit amounts
				</b-button>
			</div>
			<div class="text-bold text-red margin-top-5 margin-bottom-20">
				... reset credit debit amounts of transactions (dispatch's credits,
				purchase invoice's debits
			</div>
			<div>
				<b-button
					class="is-info margin-top-30"
					style="width: 350px"
					:disabled="isFetchBtnDisabled"
					@click="onFetchInventoryValues"
				>
					Fetch inventory values from Database
				</b-button>
			</div>
			<div class="text-bold text-blue-600 margin-top-5 margin-bottom-20">
				... fetch debit credit amounts from database
			</div>
			<div>
				<b-button
					class="is-success margin-top-30"
					style="width: 350px"
					:disabled="isSetBtnDisabled"
					@click="onSetCreditDebit"
				>
					Set Credit Debit From Inventory
				</b-button>
				<div class="text-bold text-green margin-top-5 margin-bottom-20">
					... set credit debit values values from inventory
				</div>
			</div>
		</div>
	</layout-default>
</template>
<script>
import ResetCreditDebitAmountsMutation from '@/_srcv2/views/pages/startup/components/set-inventory-values/graphql/ResetCreditDebitAmountsMutation.graphql'
import GetInventoryCreditDebitAmountsQuery from '@/_srcv2/views/pages/startup/components/set-inventory-values/graphql/GetInventoryCreditDebitAmountsQuery.graphql'
import SetCreditDebitAmountsMutation from '@/_srcv2/views/pages/startup/components/set-inventory-values/graphql/SetCreditDebitAmountsMutation.graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import Router from '@/router'
import { ref, reactive, onMounted } from '@vue/composition-api'

export default {
	name: 'SetCreditDebitValuesFromInventory',
	setup() {
		const isResetBtnDisabled = ref(false)
		const isFetchBtnDisabled = ref(true)
		const isSetBtnDisabled = ref(true)
		onMounted(() => {
			isResetBtnDisabled.value = false
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
		})
		// * -----------------------------------------------------------------------------------------
		const { mutate: resetMutation, onDone: onDoneReset } = useMutation(
			ResetCreditDebitAmountsMutation,
			() => ({
				variables: {
					company_id: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		onDoneReset((result) => console.log(result))
		// ! -----------------------------------------------------------------------------------------
		const onReset = () => {
			resetMutation()
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = false
			isSetBtnDisabled.value = true
		}
		// * -----------------------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchInventoryCreditDebit } = useQuery(
			GetInventoryCreditDebitAmountsQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// ! -----------------------------------------------------------------------------------------
		const dataArray = ref([])
		const onFetchInventoryValues = () => {
			refetchInventoryCreditDebit().then((result) => {
				console.log('result.data.stock', result.data.stock)
				dataArray.value = result.data.stock
			})
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = false
		}
		// * -----------------------------------------------------------------------------------------
		const mutationVariables = reactive({
			company_id: Store.getters.getUserCurrentCompany,
			stock_id: '',
			credit_amount: 0,
			debit_amount: 0,
		})
		const { mutate: setCreditDebitMutation, onDone: onDoneSetCreditDebit } =
			useMutation(SetCreditDebitAmountsMutation, () => ({
				variables: mutationVariables,
			}))
		onDoneSetCreditDebit((result) => console.log(result))
		// ! -----------------------------------------------------------------------------------------
		const onSetCreditDebit = () => {
			dataArray.value.forEach((item) => {
				mutationVariables.stock_id = item.stock_id
				mutationVariables.credit_amount = item.inventory_credit
				mutationVariables.debit_amount = item.inventory_debit
				setCreditDebitMutation()
			})
			isResetBtnDisabled.value = false
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
			Router.push({ name: 'Startup' })
		}
		// * -------------------------------------------------------
		return {
			onReset,
			onFetchInventoryValues,
			onSetCreditDebit,
			isResetBtnDisabled,
			isFetchBtnDisabled,
			isSetBtnDisabled,
		}
	},
}
</script>
